export default function() {
  // Only inject on client-side to avoid SSR issues
  if (process.client) {
    // Create and inject the script directly
    const script = document.createElement('script')
    script.src = 'https://cmp.osano.com/xxTCs6jSBH/65284991-b117-4f6b-a7c1-8a634114d3ce/osano.js'
    script.async = false
    
    // Wait for DOM to be ready
    if (document.head) {
      // Insert as first script in head
      const firstScript = document.head.querySelector('script')
      if (firstScript) {
        document.head.insertBefore(script, firstScript)
      } else {
        document.head.appendChild(script)
      }
    } else {
      // If head isn't ready yet, use DOMContentLoaded
      document.addEventListener('DOMContentLoaded', () => {
        const firstScript = document.head.querySelector('script')
        if (firstScript) {
          document.head.insertBefore(script, firstScript)
        } else {
          document.head.appendChild(script)
        }
      })
    }
  }
} 